import React from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TechPCTCss from '../../Style/AIPCT.module.scss'

function PTCProject() {
    const defaultAI = [
        {
            key: 1,
            title: 'VUFORIA STUDIO',
            text1: '提升工業生產潛力穩固 SOP 操作流程： Vuforia Studio 利用現有的 3D CAD 數據和動畫序列，建立可視化的 SOP 操作說明。',
            text2: '可視化的 IoT 數據： Vuforia Studio 從 ThingWorx 平台和企業系統顯現可視化的 IoT 數據。',
            text3: '提升 XR 內容擴展性： Vuforia Studio 可在您喜歡的各式移動設備上呈現所需的 XR 內容。',
            text4: '豐富的用戶體驗： Vuforia Studio 利用創新的跟踪方法，更快地進行目標識別，並將 3D 虛擬產品精確放置到現實環境中。',

            url: './images/PTC/Vuforia1.webp',
        },
        {
            key: 2,
            title: 'VUFORIA ENGINE',
            text1: '建立引人入勝的 3D 產品演示無與倫比的準確性： 借助 Vuforia Engine 領先的計算機視覺技術，在各種環境中實現強大而精確的AR體驗。',
            text2: '提升創造力： 利用 Vuforia Engine 在各式開發平台上創建客製化的XR體驗。',
            text3: '拓展使用者範圍： Vuforia Engine 可在 IOS、Android 和 Windows 系統上盡情開發XR應用，最大化的拓展應用程式使用者數量。',
            text4: '領先的視覺辨識： Vuforia Engine 針對圖像，3D 對象和環境，提供動態識別功能，增加開發靈活性。',

            url: './images/PTC/Vuforia2.webp',
        },
        {
            key: 3,
            title: 'VUFORIA CHALK',
            text1: '體驗全方位的遠端專家指導減少現場花費的時間： 由經驗豐富的團隊成員提供及時的 Vuforia Chalk 全方位指導，減少不必要的差旅費和重複訪問的費用。',
            text2: '減少機台修復時間： 將您的現場技術人員與故障排除專家利用 Vuforia Chalk 聯繫起來，減少停機時間並加快問題解決速度。',
            text3: '提高安全性和合規性： 專家能夠通過 Vuforia Chalk 標註指出問題的具體細節，從而確保現場技術人員在維護危險機械和工作環境時的安全。',
            text4: '啟用客戶自助服務： 通過 Vuforia Chalk 客戶自主授權。將您的客戶直接與他們需要的專家聯繫起來，幫助客戶快速地進行故障排除和解決問題。',

            url: './images/PTC/Vuforia3.webp',
        },
    ]
    return (
        <div>
            {defaultAI.map((item) => (
                <Container key={item.key} fluid className="mb-5">
                    <Row className="d-flex align-items-end">
                        <Col className={TechPCTCss.TitleLeft} lg={5} md={5} sm={12} xs={12}>
                            <Row className="d-flex justify-content-center">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className={TechPCTCss.Title}>
                                        <h2>{item.title}</h2>
                                        <ul>
                                            <li>
                                                {' '}
                                                <p>{item.text1}</p>
                                            </li>
                                            <li>
                                                {' '}
                                                <p>{item.text2}</p>
                                            </li>
                                            <li>
                                                {' '}
                                                <p>{item.text3}</p>
                                            </li>
                                            <li>
                                                {' '}
                                                <p>{item.text4}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            lg={(7, { order: 'last' })}
                            md={(12, { order: 'last' })}
                            sm={(12, { order: 'first' })}
                            xs={(12, { order: 'first' })}
                        >
                            <img className={TechPCTCss.ImgLeft} src={item.url} alt="" />
                        </Col>
                    </Row>
                </Container>
            ))}
        </div>
    )
}

export default PTCProject
