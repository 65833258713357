import React from 'react'
import VRModelView from '../Components/TechVR/VRModelView'
import VRSwipe from '../Components/TechVR/VRSwipe'
import VRVideo from '../Components/TechVR/VRVideo'

import Unique from '../Components/Unique'
function TechVR() {
    return (
        <div>
            <VRModelView />
            <Unique />
            <VRSwipe />
            <VRVideo />
        </div>
    )
}

export default TechVR
