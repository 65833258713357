import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '@google/model-viewer'
import TechARCss from '../../Style/TechXR.module.scss'

function VRModelView() {
    return (
        <div className="RWDBanner">
            {' '}
            <Container fluid>
                <Row className={TechARCss.fullScreen}>
                    <Col
                        className={TechARCss.backgroundImage}
                        lg={(5, { order: 'first' })}
                        sm={(5, { order: 'first' })}
                        xs={(12, { order: 'last' })}
                    >
                        <Row>
                            <Col lg={12} className={TechARCss.ARTitle}>
                                <div>
                                    <h1
                                        style={{ fontWeight: 'bold' }}
                                        // className="display-1"
                                        className={TechARCss.Label}
                                    >
                                        <span className={TechARCss.ARAspanShowRWD}>
                                            {' '}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                        </span>
                                        VR虛擬實境
                                    </h1>
                                    <div className={TechARCss.BannerTitle}></div>
                                </div>
                                <h1 className="display-2" style={{ fontWeight: 'bold' }}>
                                    南科高空車
                                </h1>
                            </Col>
                            <Col className={TechARCss.ARText} lg={12}>
                                <h1 className="display-5">模型展示</h1>
                            </Col>
                            <Col className={TechARCss.ARModel} lg={12}>
                                <a href={`${process.env.PUBLIC_URL}/#/project`}>
                                    <img src={'./icon/了解更多.png'} alt="" />
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="d-flex justify-content-start" lg={7} sm={7} xs={12}>
                        <model-viewer
                            id="reveal"
                            style={{ width: '100%', height: '100%' }}
                            camera-orbit="15deg 90deg 5.5m"
                            src="./gltf/car/car.gltf"
                            alt="A rock"
                            disable-zoom
                            camera-controls
                            // interaction-prompt="when-focused"
                            max-camera-orbit="auto 90deg 100%"
                            min-camera-orbit="auto 90deg 100%"
                            ar
                            poster="./gltf/car.webp"
                            ar-modes="webxr scene-viewer quick-look"
                            bounds="tight"
                            enable-pan
                            shadow-intensity="1.05"
                            exposure="1"
                            shadow-softness="1"
                        ></model-viewer>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default VRModelView
