import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
// import Button from 'react-bootstrap/Button'

// import TechMRCss from '../../Style/TechXR.module.scss'
function MRBannerVideo() {
    return (
        <div className="RWDBanner">
            {' '}
            <Carousel className="mb-5" controls={false} indicators={false}>
                <Carousel.Item>
                    <video
                        className="d-block w-100"
                        // className={TechMRCss.MRVideo}
                        src="./video/mp4/單獨頁面/MR/banner/混合實境智能機台維修服務平台開發計畫-4.mp4"
                        alt="VRvideo"
                        muted
                        loop
                        playsInline
                        autoPlay
                    />
                    {/* <Carousel.Caption>
                        <Button variant="light">Light</Button>{' '}
                    </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default MRBannerVideo
