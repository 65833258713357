import React from 'react'
import ContactLabel from '../Components/Contact/ContactLabel'
import { ContactFrom } from '../Components/Contact/ContactFrom'
import ContactMap from '../Components/Contact/ContactMap'

function Contact() {
    return (
        <div>
            <ContactLabel />
            <ContactFrom />
            <ContactMap />
        </div>
    )
}

export default Contact
