import React from 'react'
import About from '../Components/AboutUs/About'
import Timeline from '../Components/AboutUs/Timeline'
import Future from '../Components/AboutUs/Future'
import Mascot from '../Components/AboutUs/Mascot'
import '../Style/golbal.scss'

function AboutUs() {
    return (
        <>
            <div>
                <About />
                <Timeline />
                <Future />
                {/* <Mascot /> */}
            </div>
        </>
    )
}

export default AboutUs
