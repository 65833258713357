import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContactUsCss from '../../Style/Contact.module.scss'
function ContactLabel() {
    return (
        <div>
            {' '}
            <Container className="my-5">
                <Row className="d-flex align-items-center justify-content-end">
                    <Col className={ContactUsCss.label} xs={12} md={12}>
                        <h1>聯絡我們</h1>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="my-5">
                <Row>
                    <Col className="unique d-flex  align-items-center justify-content-start" lg={7}>
                        <div className="m-2">
                            <h3 className="mb-0">需求諮詢/Demand consultation</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactLabel
