import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HomeCss from '../../Style/Home.module.scss'
function TechAR() {
    return (
        <Container fluid="lg, xl, xxl">
            <Row className="d-flex justify-content-center">
                <Col lg="1" sm={{ order: 'first' }} className="d-flex align-items-center justify-content-end"></Col>
                <Col lg="3" className="d-flex align-items-center">
                    <div className={HomeCss.borderLeft}>
                        <h2>MR混合實境</h2>
                        <h5>
                            將AR與VR結合的技術，透過眼鏡裝置，將虛擬情境堆疊於現實空間之中，創造一個真實與虛假併行的全新空間！體驗者可以看見現實空間，亦可以看見虛擬的人物、圖像以及資訊。
                        </h5>
                    </div>
                </Col>
                <Col
                    lg="2"
                    sm={('3', { order: 'last' })}
                    xs={('3', { order: 'last' })}
                    md="auto"
                    className="d-flex align-items-center justify-content-center"
                >
                    <a href={`${process.env.PUBLIC_URL}/#/techMR`}>
                        <img className={HomeCss.buttonImage} src={'./icon/MR介紹.png'} alt="" loading="lazy" />
                        <img className={HomeCss.buttonRWDImage} src={'./icon/MR介紹-New.png'} alt="" loading="lazy" />
                    </a>
                </Col>

                <Col lg={('6', { order: 'last' })}>
                    {' '}
                    {/* <img className={HomeCss.GifImage} src={'./images/gif/MR.gif'} alt="" /> */}
                    <video
                        className={HomeCss.GifImage}
                        controls={false}
                        data-autoplay
                        src="./video/mp4/home/MR-mp4.mp4"
                        type="video/mp4"
                        loop
                        muted="muted"
                        playsInline
                    ></video>
                </Col>
            </Row>
        </Container>
    )
}

export default TechAR
