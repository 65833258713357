import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Unique from '../../Components/Unique'
import TechAICss from '../../Style/AIPCT.module.scss'
function AIBanner() {
    return (
        <>
            <Container className="RWDBanner" fluid>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>

                        <img className={TechAICss.Image} src="./images/AI/AIBanner.webp" alt="" />
                    </Col>
                </Row>
            </Container>
            <div className={TechAICss.Label}>
                <Unique />
            </div>
        </>
    )
}

export default AIBanner
