import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import TechARCss from '../../Style/TechXR.module.scss'

function ARModelVideo() {
    // background-color

    const [isShown1, setIsShown1] = useState(false)
    const [isShown2, setIsShown2] = useState(false)
    const [isShown3, setIsShown3] = useState(false)
    const [isShown4, setIsShown4] = useState(false)

    function changeBackground(e) {
        setIsShown1(true)
    }
    function Background(e) {
        setIsShown1(false)
    }
    function changeBackground2(e) {
        setIsShown2(true)
    }
    function Background2(e) {
        setIsShown2(false)
    }
    function changeBackground3(e) {
        setIsShown3(true)
    }
    function Background3(e) {
        setIsShown3(false)
    }
    function changeBackground4(e) {
        setIsShown4(true)
    }
    function Background4(e) {
        setIsShown4(false)
    }
    return (
        <div>
            <Container fluid className="mt-2">
                <Row>
                    <Col className={TechARCss.ARWebmBox} lg={6} md={12} xs={12}>
                        <Row className="p-2">
                            <Col>
                                <div
                                    className={TechARCss.OuterBox}
                                    onMouseOver={changeBackground}
                                    onMouseLeave={Background}
                                >
                                    <img className={TechARCss.ARVideoRWD} src="./images/gif/Comp-3.gif" alt="" />
                                    <h1 className="display-4">
                                        國父銅像{' '}
                                        <Button href={`${process.env.PUBLIC_URL}/#/project`} variant="outline-light">
                                            了解更多
                                        </Button>{' '}
                                    </h1>
                                    <h4>國立國父紀念館數位影像再現導覽建置</h4>
                                </div>
                                <div className={TechARCss.ARVideoShowRWD}>
                                    {isShown1 && (
                                        <div className={TechARCss.ARWebm}>
                                            <video
                                                src="./video/webm/Comp 3.webm"
                                                alt="First slide"
                                                muted
                                                loop
                                                playsInline
                                                autoPlay
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={TechARCss.ARWebmBox} lg={6} md={12} xs={12}>
                        <Row className="p-2">
                            <Col>
                                <div
                                    className={TechARCss.OuterBox}
                                    onMouseOver={changeBackground2}
                                    onMouseLeave={Background2}
                                >
                                    {/* <video
                                        className={TechARCss.ARVideoRWD}
                                        src="./video/webm/Comp 1.webm"
                                        alt="First slide"
                                        muted
                                        loop
                                        playsInline
                                        autoPlay
                                    /> */}
                                    <img className={TechARCss.ARVideoRWD} src="./images/gif/Comp-5.gif" alt="" />
                                    <h1 className="display-4">
                                        <Button href={`${process.env.PUBLIC_URL}/#/project`} variant="outline-light">
                                            了解更多
                                        </Button>{' '}
                                        大會堂
                                    </h1>
                                    <h4>國立國父紀念館數位影像再現導覽建置</h4>
                                </div>
                                <div className={TechARCss.ARVideoShowRWD}>
                                    {isShown2 && (
                                        <div className={TechARCss.ARWebm2}>
                                            <video
                                                src="./video/webm/大會堂.webm"
                                                alt="First slide"
                                                muted
                                                loop
                                                playsInline
                                                autoPlay
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className={TechARCss.ARWebmBox} lg={6} md={12} xs={12}>
                        <Row className="p-2">
                            <Col>
                                <div
                                    className={TechARCss.OuterBox}
                                    onMouseOver={changeBackground3}
                                    onMouseLeave={Background3}
                                >
                                    {/* <video
                                        className={TechARCss.ARVideoRWD}
                                        src="./video/webm/Comp 1.webm"
                                        alt="First slide"
                                        muted
                                        loop
                                        playsInline
                                        autoPlay
                                    /> */}
                                    <img className={TechARCss.ARVideoRWD} src="./images/gif/Comp-1.gif" alt="" />
                                    <h4>國立國父紀念館數位影像再現導覽建置</h4>
                                    <h1 className="display-4">
                                        砲火浮雕{' '}
                                        <Button href={`${process.env.PUBLIC_URL}/#/project`} variant="outline-light">
                                            了解更多
                                        </Button>{' '}
                                    </h1>
                                </div>
                                <div className={TechARCss.ARVideoShowRWD}>
                                    {isShown3 && (
                                        <div className={TechARCss.ARWebm}>
                                            <video
                                                src="./video/webm/Comp 1.webm"
                                                alt="First slide"
                                                muted
                                                loop
                                                playsInline
                                                autoPlay
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={TechARCss.ARWebmBox} lg={6} md={12} xs={12}>
                        <Row className="p-2">
                            <Col>
                                <div
                                    className={TechARCss.OuterBox}
                                    onMouseOver={changeBackground4}
                                    onMouseLeave={Background4}
                                >
                                    {/* <video
                                        className={TechARCss.ARVideoRWD}
                                        src="./video/webm/Comp 3.webm"
                                        alt="First slide"
                                        muted
                                        loop
                                        playsInline
                                        autoPlay
                                    /> */}
                                    <img className={TechARCss.ARVideoRWD} src="./images/gif/Comp-4.gif" alt="" />
                                    <h4>國立國父紀念館數位影像再現導覽建置</h4>
                                    <h1 className="display-4">
                                        <Button href={`${process.env.PUBLIC_URL}/#/project`} variant="outline-light">
                                            了解更多
                                        </Button>{' '}
                                        建築模型
                                    </h1>
                                </div>
                                <div className={TechARCss.ARVideoShowRWD}>
                                    {isShown4 && (
                                        <div className={TechARCss.ARWebm2}>
                                            <video
                                                src="./video/webm/Comp 4_1.webm"
                                                alt="First slide"
                                                muted
                                                loop
                                                playsInline
                                                autoPlay
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ARModelVideo
