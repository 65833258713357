import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
function unique() {
    return (
        <div>
            {' '}
            <Container fluid>
                <Row>
                    <Col className="unique d-flex  align-items-center justify-content-start" lg={7}>
                        <div className="my-2">
                            <h3 className="mb-0">Why We are unique / 獨門技術</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default unique
