import React from 'react'
import ModelView from '../Components/TechAR/ARModelView'
import ARSwipe from '../Components/TechAR/ARSwipe'
import Unique from '../Components/Unique'
import ARModelVideo from '../Components/TechAR/ARModelVideo'

function TechAR() {
    return (
        <div>
            <ModelView />
            <Unique />
            <ARSwipe />
            <ARModelVideo />
        </div>
    )
}

export default TechAR
