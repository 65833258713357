import React from 'react'
import PCTBanner from '../Components/PCT/PCTBanner'
import PCTProject from '../Components/PCT/PTCProject'

function PTC() {
    return (
        <div>
            <PCTBanner />
            <PCTProject />
        </div>
    )
}

export default PTC
