import React, { createRef, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import BannerCss from '../../Style/HomeBanner.module.scss'
function BannerVideo() {

    return (
        <Carousel indicators={false} controls={false}>
            <Carousel.Item>
                <div className={BannerCss.playerWrapper}>
                    <video
                        className="d-block w-100"
                        data-autoplay
                        src="./video/wj-banner-1.mp4"
                        type="video/mp4"
                        loop
                        muted
                        controls={false}
                        playsInline
                    ></video>
                </div>
                <div className={BannerCss.playerRWDWrapper}>
                    <video
                        className="d-block w-100"
                        data-autoplay
                        src="./video/wj-banner-3.mp4"
                        type="video/mp4"
                        loop
                        muted
                        controls={false}
                        playsInline
                    ></video>
                </div>


                {/* <img src={'./images/gif/AR.gif'} alt="" /> */}
            </Carousel.Item>
        </Carousel>
    )
}

export default BannerVideo
