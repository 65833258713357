import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import TechVRCss from '../../Style/TechXR.module.scss'
function VRSwipe() {
    const defaultAR = [
        {
            key: 1,
            title: '多人協作',
            text: '借助AI與伺服器串接,多人共同協力完成一個既定任務是一個可實現的內容呈現方式',
            url: './images/VR/多人協作.webp',
        },
        // {
        //     key: 2,
        //     title: '精準場景建置',
        //     text: '藉由立體掃描建立了相當精準的場景3D模型',
        //     url: './images/AR/page1.png',
        // },
        {
            key: 2,
            title: '模組化開發',
            text: '模組化開發讓程式碼的可重複使用性大幅提升,反映在專案上是時程的縮短與效率的增加',
            url: './images/VR/模組化開發.webp',
        },
    ]
    return (
        <div className="mt-4">
            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
                {defaultAR.map((item) => (
                    <SwiperSlide className="mb-5" key={item.key}>
                        <Container>
                            <Row className="d-flex align-items-center">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <img style={{ width: '100%', height: 'auto' }} src={item.url} alt="" />
                                </Col>
                                <Col className={TechVRCss.SwipeTitleBg} lg={6} md={6} sm={12} xs={12}>
                                    <div className={TechVRCss.SwipeTitle}>
                                        <h2>{item.title}</h2> <p>{item.text}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default VRSwipe
