import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AboutUsCss from '../../Style/AboutUs.module.scss'
function Future() {
    return (
        <Container className="mt-5">
            <Row className="d-flex  justify-content-center ">
                <Col className={AboutUsCss.label} xs={12} md={12} lg={12}>
                    <h1>公司展望</h1>
                </Col>
                <Col xs={12} md={12} lg={12}>
                    <img className={AboutUsCss.FullImage} src={'./images/future.png'} alt="" />
                </Col>
                <Col className="my-3" xs={12} md={12} lg={6}>
                    <h5>在科技革新的浪潮中，發揮跨領域、混合應用開發的特長，提供多元、專業的服務。</h5>
                </Col>
            </Row>
        </Container>
    )
}

export default Future
