import React from 'react'
import ProjectVideo from '../Components/Project/ProjectVideo'

function Project() {
    return (
        <div>
            <ProjectVideo />
        </div>
    )
}

export default Project
