import React from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TechMRCss from '../../Style/TechXR.module.scss'
function MRPic() {
    return (
        <div>
            {' '}
            <Container fluid className="my-5">
                <Row className="d-flex align-items-center justify-content-end">
                    <Col className={TechMRCss.MRTitleLeft} lg={5} md={5} sm={12} xs={12}>
                        <div className={TechMRCss.MRTitle}>
                            <h1 className="display-3">易利修MR</h1>
                            <h5>混合實境智能機台維修服務平台開發計畫</h5>{' '}
                            <Button
                                href={`${process.env.PUBLIC_URL}/#/project`}
                                className="mt-4"
                                variant="outline-light"
                            >
                                了解更多{' '}
                            </Button>{' '}
                        </div>
                    </Col>
                    <Col
                        lg={(7, { order: 'last' })}
                        md={(7, { order: 'last' })}
                        sm={(12, { order: 'first' })}
                        xs={(12, { order: 'first' })}
                    >
                        <img className={TechMRCss.MRImgLeft} src="./images/MR/易利修MR.webp" alt="" />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="d-flex align-items-center">
                    <Col
                        lg={(7, { order: 'first' })}
                        md={(7, { order: 'first' })}
                        sm={(12, { order: 'first' })}
                        xs={(12, { order: 'first' })}
                    >
                        <img className={TechMRCss.MRImgRight} src="./images/MR/勞研所天車.webp" alt="" />
                    </Col>
                    <Col className={TechMRCss.MRTitleRight} lg={5} md={5} sm={12} xs={12}>
                        <div className={TechMRCss.MRTitle}>
                            <h1 className="display-3">勞研所天車</h1>
                            <h4>擴增實境技術檢測工具開發</h4>
                            <Button
                                href={`${process.env.PUBLIC_URL}/#/project`}
                                className="mt-4"
                                variant="outline-light"
                            >
                                了解更多{' '}
                            </Button>{' '}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MRPic
