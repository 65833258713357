import React from 'react'
import SkillShow from '../Components/TechSkill/SkillShow'

function Techskill() {
    return (
        <div>
            <SkillShow />
        </div>
    )
}

export default Techskill
