import React, { useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NavCss from '../Style/Navbar.module.scss'

function NavBar() {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }
    const [expanded, setExpanded] = useState(false)
    return (
        <div className={NavCss.navBg}>
            <Navbar expanded={expanded} collapseOnSelect expand="lg" variant="dark">
                <Container fluid>
                    <Navbar.Brand className={NavCss.logoWejump} href={`${process.env.PUBLIC_URL}/#/`}>
                        {' '}
                        <img
                            alt="logo"
                            src="./icon/logo.svg"
                            width="200"
                            height="40"
                            className="d-inline-block align-top"
                        />{' '}
                    </Navbar.Brand>

                    <Navbar.Toggle
                        onClick={() => setExpanded(expanded ? false : 'expanded')}
                        aria-controls="responsive-navbar-nav"
                    />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav>
                            <Nav>
                                <Link onClick={() => setExpanded(false)} to="/" activeClassName="active">
                                    <div className={NavCss.navTitle}>首頁</div>
                                </Link>
                                <Link onClick={() => setExpanded(false)} to="/aboutUs">
                                    <div className={NavCss.navTitle}> 關於旺捷</div>
                                </Link>
                                <Link onClick={() => setExpanded(false)} to="/project">
                                    <div className={NavCss.navTitle}> 作品案例</div>
                                </Link>
                                <Link onClick={() => setExpanded(false)} to="/techskill">
                                    <div className={NavCss.navTitle}> 技術服務</div>
                                </Link>
                                <a
                                    onClick={() => {
                                        setExpanded(false)
                                        openInNewTab('https://wejump3d.wj-ar.com/room4-demo')
                                    }}
                                >
                                    <div className={NavCss.navTitle}> 虛擬官網</div>
                                </a>

                                <Link onClick={() => setExpanded(false)} to="/contactUs">
                                    <div className={NavCss.navTitle}> 聯絡我們</div>
                                </Link>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavBar
