import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
function CAVEBanner() {
    return (
        <div className="RWDBanner">
            {' '}
            <Carousel className="mb-5" controls={false} indicators={false}>
                <Carousel.Item>
                    <video
                        className="d-block w-100"
                        // className={TechMRCss.MRVideo}

                        src="./video/mp4/單獨頁面/CAVE/現場版_下水道-4.mp4"
                        alt="VRvideo"
                        muted
                        loop
                        playsInline
                        autoPlay
                    />
                    {/* <Carousel.Caption>
                        <Button href={`${process.env.PUBLIC_URL}/#/project`} variant="light">
                            了解更多
                        </Button>{' '}
                    </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default CAVEBanner
