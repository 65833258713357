import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import TechVRCss from '../../Style/TechXR.module.scss'
function VRVideo() {
    const defaultVR = [
        {
            key: 1,
            title: 'XR防災模擬訓練場域資訊系統開發案',
            text: '科技部南部科學園區管理局',

            url: './video/mp4/單獨頁面/VR/南科影片有聲版-3.mp4',

            link: `${process.env.PUBLIC_URL}/#/project`,
        },
        {
            key: 2,
            title: '下水道建設導覽開發工作VR互動',
            text: '內政部營建署',

            url: './video/mp4/單獨頁面/VR/虛擬實境應用於下水道建設導覽開發工作-3.mp4',
            link: `${process.env.PUBLIC_URL}/#/project`,
        },
    ]
    return (
        <div>
            {defaultVR.map((item) => (
                <Container key={item.key} fluid className="mt-5">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Carousel controls={false} indicators={false}>
                                <Carousel.Item className={TechVRCss.VRVideoItem}>
                                    <video
                                        className={TechVRCss.VRVideo}
                                        src={item.url}
                                        alt="VRvideo"
                                        muted
                                        playsInline
                                        autoPlay
                                    />
                                    <Carousel.Caption className={TechVRCss.VRVideoTitle}>
                                        <Container>
                                            <Row>
                                                <Col
                                                    className="d-flex  justify-content-end"
                                                    lg={8}
                                                    md={8}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <h3>
                                                        {item.title} <p> {item.text}</p>{' '}
                                                    </h3>
                                                </Col>
                                                <Col lg={4} md={4} sm={12} xs={12}>
                                                    <Button href={item.link} size="lg" variant="outline-light">
                                                        了解更多
                                                    </Button>{' '}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            ))}{' '}
        </div>
    )
}

export default VRVideo
