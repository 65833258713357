import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import TechCAVECss from '../../Style/TechXR.module.scss'
function CAVESwipe() {
    const defaultMR = [
        {
            key: 1,
            title: '結合AI辨識的互動投影',
            text: '結合AI智能辨識的投影互動，讓道具或使用者的動作、位置、聲音都能成為觸發感應的來源，達成豐富的投影互動效果',
            url: './images/CAVE/互動投影.webp',
        },
        {
            key: 2,
            title: '結合AI辨識的互動投影',
            text: '結合AI智能辨識的投影互動，讓道具或使用者的動作、位置、聲音都能成為觸發感應的來源，達成豐富的投影互動效果',
            url: './images/AR/page1.png',
        },
    ]
    return (
        <div style={{ margin: '100px 0px' }}>
            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
                {defaultMR.map((item) => (
                    <SwiperSlide key={item.key}>
                        <Container className="mb-5">
                            <Row className="d-flex align-items-center">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <img className={TechCAVECss.SwipeImage} src={item.url} alt="" />
                                </Col>
                                <Col className={TechCAVECss.SwipeTitleBg} lg={6} md={6} sm={12} xs={12}>
                                    <div className={TechCAVECss.SwipeTitle}>
                                        <h2>{item.title}</h2> <p>{item.text}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default CAVESwipe
