import React from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TechCAVECss from '../../Style/TechXR.module.scss'
function MRPic() {
    return (
        <div>
            {' '}
            <Container fluid>
                <Row className="d-flex align-items-center">
                    <Col
                        lg={(7, { order: 'first' })}
                        md={(7, { order: 'first' })}
                        sm={(12, { order: 'first' })}
                        xs={(12, { order: 'first' })}
                    >
                        <img className={TechCAVECss.CAVEImgRight} src="./images/CAVE/cave.png" alt="" />
                    </Col>
                    <Col className={TechCAVECss.MRTitleRight} lg={5} md={5} sm={12} xs={12}>
                        <div className={TechCAVECss.MRTitle}>
                            <h1 className="display-3">南科下水道</h1>
                            <h4>科技部南部科學園區管理局</h4>
                            <Button
                                className="mt-4"
                                href={`${process.env.PUBLIC_URL}/#/project`}
                                variant="outline-light"
                            >
                                了解更多{' '}
                            </Button>{' '}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MRPic
