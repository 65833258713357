import React, { Component } from 'react'
import logo from './logo.svg'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom'
// 目錄
import Navbar from './Components/Navbar'
// 頁尾
import Footer from './Components/Footer'

// 首頁
import Home from './Page/Home'
// 關於
import AboutUs from './Page/AboutUs'
// 作品案例
import Project from './Page/Project'
// 技術服務
import Techskill from './Page/Techskill'
// 技術AR
import TechAR from './Page/TechAR'
// 技術VR
import TechVR from './Page/TechVR'
// 技術MR
import TechMR from './Page/TechMR'
// 技術AI
import TechAI from './Page/TechAI'
// PTC
import PTC from './Page/PTC'
// CAVE
import CAVE from './Page/CAVE'
// 聯絡我們
import Contact from './Page/Contact'
function App() {
    return (
        <>
            <div className="App">
                <Router>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Home />}></Route>
                        <Route path="/aboutUs" element={<AboutUs />}></Route>
                        <Route path="/project" element={<Project />}></Route>
                        <Route path="/techskill" element={<Techskill />}></Route>
                        <Route path="/techAR" element={<TechAR />}></Route>
                        <Route path="/techVR" element={<TechVR />}></Route>
                        <Route path="/techMR" element={<TechMR />}></Route>
                        <Route path="/techAI" element={<TechAI />}></Route>
                        <Route path="/PTC" element={<PTC />}></Route>
                        <Route path="/CAVE" element={<CAVE />}></Route>
                        <Route path="/contactUs" element={<Contact />}></Route>
                    </Routes>
                    <Footer />
                </Router>
            </div>
        </>
    )
}

export default App
