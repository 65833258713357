import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HomeCss from '../../Style/Home.module.scss'
function Space3D() {
    return (
        <Container fluid>
            <Row className="d-flex justify-content-center">
                <Col lg="1" className="d-flex align-items-center justify-content-end"></Col>
                <Col lg="3" className="d-flex align-items-center justify-content-end">
                    <div>
                        <div className={HomeCss.borderLeft}>
                            <h2>元宇宙虛擬展間</h2>
                            <h5>以WEB VR方式呈現虛擬展間，提供高自由度的移動、動態3D/2D物件展示，以及資訊點設置</h5>
                        </div>
                        <a href={`${process.env.PUBLIC_URL}/#/project`}>
                            <img className={HomeCss.buttonImageUp} src={'./icon/進入展間.png'} alt="" loading="lazy" />
                            <img
                                className={HomeCss.buttonRWDImageUp}
                                src={'./icon/進入展間-New.png'}
                                alt=""
                                loading="lazy"
                            />
                        </a>
                    </div>
                </Col>

                {/* <Col
                    lg="3"
                    sm={('3', { order: 'last' })}
                    xs={('3', { order: 'last' })}
                    md="auto"
                    className="d-flex align-items-center justify-content-center "
                >
                   
                </Col> */}

                <Col className="d-flex align-items-center justify-content-end my-3" lg={('8', { order: 'last' })}>
                    {' '}
                    <img className={HomeCss.FullImage} src={'./images/3dbackground-webp.webp'} alt="" loading="lazy" />
                </Col>
            </Row>
        </Container>
    )
}

export default Space3D
