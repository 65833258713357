import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContactUsCss from '../../Style/Contact.module.scss'
function ContactMap() {
    return (
        <div>
            {' '}
            <Container fluid className="my-5">
                <Row className="d-flex align-items-center justify-content-center">
                    <Col xs={12} md={12}>
                        <iframe
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.6789658651765!2d121.52151331536112!3d25.044966644004987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a97c8433d101%3A0xa441c2c33899d8b5!2sWEJUMP%20AR!5e0!3m2!1sen!2stw!4v1656921005697!5m2!1sen!2stw"
                            className={ContactUsCss.ContactMap}
                            allowFullScreen={true}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactMap
