import React from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TechAICss from '../../Style/AIPCT.module.scss'

function AIProject() {
    const defaultAI = [
        {
            key: 1,
            title: '物件辨識',
            text: '物件辨識是用來辨別所有為固體的物件，需要框標出物件的範圍，並且定義物件的類別，最終可預測出該物件及該物件的機率',
            url: './images/AI/物件.webp',
        },
        {
            key: 2,
            title: '姿態辨識',
            text: '姿態辨識是用來判斷人物的動作，像是站立、舉手、坐下、蹲下以及跑步等等動作，而其它生命體則不適用',
            url: './images/AI/姿勢.webp',
        },
        {
            key: 3,
            title: '定位',
            text: '藉由攝影機來拍攝固定畫面，並定義在影像中要定位的區域，再判斷人物是否在該區域內',
            url: './images/AI/定位.webp',
        },
        {
            key: 4,
            title: '手勢辨識',
            text: '手勢辨識是藉由提取人的手掌之特徵點，以此來判斷人的手勢動作，可以用來辨識像是握拳、猜拳等等的動作',
            url: './images/AI/手勢.webp',
        },
    ]
    return (
        <div>
            {defaultAI.map((item) => (
                <Container key={item.key} fluid className="mb-5">
                    <Row className="d-flex align-items-center justify-content-start">
                        <Col className={TechAICss.TitleLeft} lg={5} md={5} sm={12} xs={12}>
                            <Row className="d-flex justify-content-center">
                                <Col lg={8} md={8} sm={12} xs={12}>
                                    <div className={TechAICss.Title}>
                                        <h2>{item.title}</h2> <p>{item.text}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            lg={(7, { order: 'last' })}
                            md={(7, { order: 'last' })}
                            sm={(12, { order: 'first' })}
                            xs={(12, { order: 'first' })}
                        >
                            <img className={TechAICss.ImgLeft} src={item.url} alt="" />
                        </Col>
                    </Row>
                </Container>
            ))}
        </div>
    )
}

export default AIProject
