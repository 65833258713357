import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import TechARCss from '../../Style/TechXR.module.scss'
function ARSwipe() {
    const defaultAR = [
        {
            key: 1,
            title: '空間辨識',
            text: '基於先進的場景建置與空間建置技術讓定位發生與無形,再也不需額外的特定圖像來輔助定位',
            url: './images/AR/空間辨識.webp',
        },
        {
            key: 2,
            title: '精準場景導引',
            text: '有了精準模型與定位技術,導引功能自然變得可達成',
            url: './images/AR/精準場景導引.webp',
        },
        {
            key: 3,
            title: '模組化開發',
            text: '模組化開發讓程式碼的可重複使用性大幅提升,反映在專案上是時程的縮短與效率的增加',
            url: './images/AR/模組化開發.webp',
        },
    ]
    return (
        <div className="mt-4">
            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
                {defaultAR.map((item) => (
                    <SwiperSlide key={item.key}>
                        <Container className="mb-5">
                            <Row className="d-flex align-items-center">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <img style={{ width: '100%', height: 'auto' }} src={item.url} alt="" />
                                </Col>
                                <Col className={TechARCss.SwipeTitleBg} lg={6} md={6} sm={12} xs={12}>
                                    <div className={TechARCss.SwipeTitle}>
                                        <h2>{item.title}</h2> <p>{item.text}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default ARSwipe
