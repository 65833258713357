import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import HomeCss from '../../Style/Home.module.scss'
function TechPTC() {
    return (
        <Container fluid>
            <Row className="justify-content-center">
                <Col lg="1" className="d-flex align-items-center justify-content-end"></Col>
                <Col lg="3" className="d-flex align-items-center justify-content-center">
                    <div>
                        <div className={HomeCss.borderLeft}>
                            <h2>PTC Vuforia代理</h2>
                            <h5>
                                穩固 SOP 操作流程 豐富的用戶體驗： Vuforia Studio
                                利用創新的跟踪方法，更快地進行目標識別，並將 3D 虛擬產品精確放置到現實環境中。
                            </h5>
                        </div>
                        <a href={`${process.env.PUBLIC_URL}/#/PTC`}>
                            <img className={HomeCss.buttonImageUp} src={'./icon/技術介紹.png'} alt="" loading="lazy" />
                            <img
                                className={HomeCss.buttonRWDImageUp}
                                src={'./icon/技術介紹-New.png'}
                                alt=""
                                loading="lazy"
                            />
                        </a>
                    </div>
                </Col>

                <Col lg="7" className="d-flex align-items-center justify-content-center my-3">
                    {' '}
                    <img className={HomeCss.FullImage} src={'./images/PTC-webp.webp'} alt="" loading="lazy" />
                </Col>
                <Col lg={('1', { order: 'last' })} className="d-flex align-items-center justify-content-end"></Col>
            </Row>
        </Container>
    )
}

export default TechPTC
