import React from 'react'
import BannerVideo from '../Components/Home/BannerVideo'
import TechAR from '../Components/Home/TechAR'
import TechVR from '../Components/Home/TechVR'
import TechMR from '../Components/Home/TechMR'
import Tsparticles from '../Components/Home/Tsparticles'
import Space3D from '../Components/Home/Space3D'
import TechAi from '../Components/Home/TechAi'
import Cave from '../Components/Home/Cave'
import Matterport from '../Components/Home/Matterport'
import Online3D from '../Components/Home/Online3D'
import TechPTC from '../Components/Home/TechPTC'
import Company from '../Components/Home/Company'

import HomeCss from '../Style/Home.module.scss'
import ReactFullpage from '@fullpage/react-fullpage'
import Footer from '../Components/Footer'

const fullpageOptions = {
    lockAnchors: false,
    setFitToSection: false,
    navigation: true,
    navigationPosition: 'right',
    scrollOverflow: false,
    recordHistory: false,
    verticalCentered: true,
    loopBottom: false,
    loopTop: false,
    scrollBar: true,
    autoScrolling: true,
    fitToSection: false,
    responsiveWidth: 480,
    parallax: true,
    sectionSelector: '.section',
    css3: true,
    scrollingSpeed: 100,
    fitToSectionDelay: 100,
}
function Home() {
    return (
        <>
            <div>
                <>
                    {' '}
                    <ReactFullpage
                        {...fullpageOptions}
                        render={({ state, fullpageApi }) => {
                            return (
                                <ReactFullpage.Wrapper>
                                    <div className="section">
                                        <BannerVideo />{' '}
                                    </div>{' '}
                                    <div className="section">
                                        <TechAR />
                                    </div>{' '}
                                    <div className="section ">
                                        <TechVR />
                                    </div>{' '}
                                    <div className="section">
                                        <TechMR />
                                    </div>{' '}
                                    <div className="section ">
                                        <Space3D />
                                    </div>{' '}
                                    <div className="section ">
                                        <TechAi />
                                    </div>{' '}
                                    <div className="section">
                                        <Cave />
                                    </div>{' '}
                                    <div className="section">
                                        <Matterport />
                                    </div>{' '}
                                    {/* <div className="section">
                                        <Online3D />
                                    </div>{' '} */}
                                    <div className="section ">
                                        <TechPTC />
                                    </div>{' '}
                                    <div
                                        style={{
                                            background:
                                                'linear-gradient(0deg, #DDF9FC 33.15%, rgba(255, 255, 255, 0) 100%)',
                                        }}
                                        className="section  section12 fp-responsive fp-auto-height-responsive w-full flex justify-center items-center"
                                    >
                                        <Company />
                                    </div>{' '}
                                </ReactFullpage.Wrapper>
                            )
                        }}
                    />
                    <Tsparticles />
                </>
            </div>
        </>
    )
}

export default Home
