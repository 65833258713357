import React from 'react'
import CAVEBanner from '../Components/CAVE/CAVEBanner'
import CAVESwipe from '../Components/CAVE/CAVESwipe'
import CAVEPic from '../Components/CAVE/CAVEPic'

function CAVE() {
    return (
        <div>
            <CAVEBanner />
            <CAVESwipe />
            <CAVEPic />
        </div>
    )
}

export default CAVE
