import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Unique from '../../Components/Unique'
import TechPTCCss from '../../Style/AIPCT.module.scss'
function PCTBanner() {
    return (
        <div className="RWDBanner">
            {' '}
            <Container fluid>
                <Row className="d-flex align-items-center">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div>

                            <img className={TechPTCCss.Image} src="./images/PTC/PTCBanner.webp" alt="" />

                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={TechPTCCss.Label}>
                <Unique />
            </div>
        </div>
    )
}

export default PCTBanner
