import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Carousel } from 'react-bootstrap'
import HomeCss from '../../Style/Home.module.scss'
function Matterport() {
    return (
        <Container fluid>
            <Row className="d-flex justify-content-center">
                <Col lg="1" className="d-flex align-items-center justify-content-end"></Col>
                <Col lg="3" className="d-flex align-items-center justify-content-end">
                    <div>
                        <div className={HomeCss.borderLeft}>
                            <h2>Matterport實拍空間模型與導覽建置</h2>
                            <h5>
                                線上導覽製作，包含3D模式、平面模式、VR模式，嵌入網頁與APP中，提供高解析度畫質、多媒體物件的資訊點設置
                            </h5>
                        </div>
                        <a
                            href="https://my.matterport.com/show/?m=6D1uo2giNnm&sr=-.79,.86&ss=163"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className={HomeCss.buttonImageUp}
                                src={'./icon/Matterport.png'}
                                alt=""
                                loading="lazy"
                            />
                            <img
                                className={HomeCss.buttonRWDImageUp}
                                src={'./icon/Matterport-New.png'}
                                alt=""
                                loading="lazy"
                            />
                        </a>
                    </div>
                </Col>

                {/* <Col lg={'7'} className="d-flex align-items-center justify-content-center my-3">
                    <div className={HomeCss.MatteIframe}>
                        <iframe
                            width="100%"
                            height="100%"
                            title="Matterpoter"
                            src="https://my.matterport.com/show/?m=6D1uo2giNnm&sr=-.79,.86&ss=163"
                            allowfullscreen={true}
                            referrerpolicy="no-referrer-when-downgrade"
                            loading="lazy"
                        ></iframe>
                    </div>
                </Col>
                <Col lg={('1', { order: 'last' })} className="d-flex align-items-center justify-content-end"></Col> */}
                <Col className="d-flex align-items-center justify-content-center my-3" lg={('8', { order: 'last' })}>
                    {' '}
                    <img className={HomeCss.FullImage} src={'./images/Matterport-webp.webp'} alt="" loading="lazy" />
                </Col>
            </Row>
        </Container>
    )
}

export default Matterport
