import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Coverflow from 'react-coverflow'
import TechSkillCss from '../../Style/Techskill.module.scss'
const { REACT_APP_API_ENDPOINT } = process.env
function SkillShow() {
    return (
        <div>
            <Container>
                <Row>
                    <Col xs={12} md={12} className={TechSkillCss.label}>
                        <h1>技術服務</h1>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>
                        <Coverflow
                            displayQuantityOfSide={2}
                            // enableScroll={false}
                            clickable={true}
                            navigation={false}
                            enableHeading={true}
                            active={0}
                            infiniteScroll
                            media={{
                                '@media (max-width: 900px)': {
                                    width: '100%',

                                    height: '550px',

                                },
                                '@media (min-width: 900px)': {
                                    width: '100%',
                                    height: '550px',
                                },
                            }}
                        >
                            <img
                                src="./icon/skill/webp/官展_icon.webp"
                                alt="官網展間"

                                data-action={`https://wejump3d.wj-ar.com/room4-demo`}

                            />

                            <img
                                src="./icon/skill/webp/ai_icon.webp"
                                alt="AI"
                                data-action={`${process.env.PUBLIC_URL}/#/techAI`}
                            />

                            <img
                                src="./icon/skill/webp/mr_icon.webp"
                                alt="MR"
                                data-action={`${process.env.PUBLIC_URL}/#/techMR`}
                            />
                            <img
                                src="./icon/skill/webp/ar_icon.webp"
                                alt="AR"
                                data-action={`${process.env.PUBLIC_URL}/#/techAR`}
                            />
                            <img
                                src="./icon/skill/webp/vr_icon.webp"
                                alt="VR"
                                data-action={`${process.env.PUBLIC_URL}/#/techVR`}
                            />
                            <img
                                src="./icon/skill/webp/cave_icon.webp"
                                alt="CAVE"
                                data-action={`${process.env.PUBLIC_URL}/#/CAVE`}
                            />
                            <img
                                src="./icon/skill/webp/ptc_icon.webp"
                                alt="PTC Vuforia代理"
                                data-action={`${process.env.PUBLIC_URL}/#/PTC`}
                            />
                        </Coverflow>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SkillShow
