import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HomeCss from '../../Style/Home.module.scss'
function TechAi() {
    return (
        <Container fluid>
            <Row className="d-flex justify-content-center">
                <Col lg="1" className="d-flex align-items-center justify-content-end"></Col>
                <Col lg="3" className="d-flex align-items-center justify-content-end">
                    <div>
                        <div className={HomeCss.borderLeft}>
                            <h2>智能化AI動態捕捉</h2>
                            <h5>
                                項目包含肢體辨識、圖像辨識、語音辨識、位置辨識等，透過 AI
                                分析取得關鍵資訊，整合眼鏡、穿戴式裝置、監視攝影機等，提供靈活的互動機制
                            </h5>
                        </div>
                        <a href={`${process.env.PUBLIC_URL}/#/techAI`}>
                            <img className={HomeCss.buttonImageUp} src={'./icon/AI介紹.png'} alt="" loading="lazy" />
                            <img
                                className={HomeCss.buttonRWDImageUp}
                                src={'./icon/AI介紹-New.png'}
                                alt=""
                                loading="lazy"
                            />
                        </a>
                    </div>
                </Col>

                {/* <Col
                lg="3"
                sm={('3', { order: 'last' })}
                xs={('3', { order: 'last' })}
                md="auto"
                className="d-flex align-items-center justify-content-center "
            >
               
            </Col> */}

                <Col className="d-flex align-items-center justify-content-center my-3" lg={('8', { order: 'last' })}>
                    {' '}
                    <img className={HomeCss.FullImage} src={'./images/AI-webp.webp'} alt="" loading="lazy" />
                </Col>
            </Row>
        </Container>
    )
}

export default TechAi
