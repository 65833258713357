import React, { useRef, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Form, Button } from 'react-bootstrap'

import ContactUsCss from '../../Style/Contact.module.scss'
const initialFormData = Object.freeze({
    companyName: '',
    from_name: '',
    email: '',
    mobile: '',
    dateStart: '',
    dateEnd: '',
    message: '',
})
export const ContactFrom = (props) => {
    const [formData, updateFormData] = React.useState(initialFormData)
    const setFeedback = (serviceID, templateId, variables) => {
        window.emailjs
            .send(serviceID, templateId, variables)
            .then((res) => {
                console.log('Email successfully sent!')
            })
            .catch((err) => console.error('There has been an error', err))
    }
    const handleChange = (e) => {
        updateFormData({
            ...formData,
            // 移除字串起始及結尾處的空白字元
            [e.target.name]: e.target.value.trim(),
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        alert('Thank you for your massage. Your query has been forwarded')
        const templateId = 'template_qo6ibi9'
        const serviceID = 'service_xljn8qp'
        setFeedback(serviceID, templateId, {
            from_companyName: formData.from_companyName,
            from_name: formData.from_name,
            email: formData.email,
            mobile: formData.mobile,
            dateStart: formData.dateStart,
            dateEnd: formData.dateEnd,
            message: formData.message,
        })
        console.log(formData)

    }
    const BorderNone = {
        border: 'none',
        background: 'none',
    }

    const form = useRef()
    return (
        <div>
            {' '}
            <Container fluid className="my-5">
                <Row className="d-flex align-items-center justify-content-center">
                    <Col lg={5} md={5} ms={12} xs={12}>
                        <img className={ContactUsCss.imagePosition} src="./icon/logo-color.svg" alt="" />
                        <h4>
                            <p>旺捷智能感知股份有限公司</p>
                            <p>Phone: (02)2322-3938 </p>
                            <p>Email: service@wj-ar.com</p>
                            <p>100 台北市中正區忠孝東路一段9號6樓</p>
                        </h4>
                    </Col>
                    <Col lg={6} md={6} ms={10} xs={10} className={ContactUsCss.from}>
                        <Container>

                            <Form ref={form} onSubmit={handleSubmit} className="p-3">
                                {/* company */}
                                <Form.Group className="mb-3" controlId="formGridCompanyName">

                                    <Row>
                                        <Col lg={12}>
                                            {' '}
                                            <Form.Control
                                                className="border-bottom border-secondary"

                                                onChange={handleChange}
                                                style={BorderNone}
                                                name="from_companyName"
                                                type="text"
                                                placeholder="請輸入公司／品牌名稱"
                                                required

                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                {/* Name */}

                                <Form.Group className="mb-3" controlId="formGridName">

                                    <Row>
                                        <Col lg={12}>
                                            {' '}
                                            <Form.Control
                                                className="border-bottom border-secondary"

                                                onChange={handleChange}

                                                style={BorderNone}
                                                name="from_name"
                                                type="text"
                                                placeholder="請輸入姓名"

                                                required

                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>


                                {/* Email */}
                                <Form.Group className="mb-3" controlId="formGridEmail">

                                    <Row>
                                        <Col lg={12}>
                                            {' '}
                                            <Form.Control
                                                className="border-bottom border-secondary"

                                                onChange={handleChange}

                                                style={BorderNone}
                                                name="email"
                                                type="email"
                                                placeholder="請輸入e-mail"

                                                required

                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>

                                {/* phone */}
                                <Form.Group className="mb-3" controlId="formGridMobile">

                                    <Row>
                                        <Col lg={12}>
                                            {' '}
                                            <Form.Control
                                                className="border-bottom border-secondary"

                                                onChange={handleChange}
                                                style={BorderNone}
                                                name="mobile"
                                                type="tel"
                                                placeholder="聯絡電話"
                                                required
                                                minLength="10"

                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formGridDateStart">

                                    <Row>
                                        <Col className="d-flex  justify-content-start" lg={12}>
                                            {' '}
                                            <Form.Label className={ContactUsCss.textColor}>預計開案日期</Form.Label>
                                        </Col>
                                        <Col lg={12}>
                                            {' '}
                                            <Form.Control
                                                className="border-bottom border-secondary"

                                                onChange={handleChange}
                                                style={BorderNone}
                                                name="dateStart"

                                                type="date"
                                                placeholder="預計開案日期"
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formGridDateEnd">

                                    <Row>
                                        <Col className="d-flex  justify-content-start" lg={12}>
                                            {' '}
                                            <Form.Label className={ContactUsCss.textColor}>預計完成日期</Form.Label>
                                        </Col>
                                        <Col lg={12}>
                                            {' '}
                                            <Form.Control
                                                className="border-bottom border-secondary"

                                                onChange={handleChange}
                                                style={BorderNone}
                                                name="dateEnd"

                                                type="date"
                                                placeholder="預計完成日期"
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>

                                {/* Message */}

                                <Form.Group className="mb-3" id="formGridQuery">
                                    {/* <Form.Label className={ContactUsCss.textColor}>需求描述</Form.Label> */}
                                    <Form.Control
                                        placeholder="需求描述"
                                        onChange={handleChange}

                                        style={{ border: 'none' }}
                                        name="message"
                                        as="textarea"
                                        rows={3}

                                        required
                                    />
                                </Form.Group>

                                <Button variant="light" size="lg" type="submit">
                                    送出
                                </Button>

                                {/* <Button variant="light" size="lg" type="submit">
                                送出
                            </Button> */}

                            </Form>
                        </Container>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

