import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FaBeer } from 'react-icons/fa'
import AboutUsCss from '../../Style/AboutUs.module.scss'

function Timeline() {
    return (
        <div>
            <Container>
                <Row>
                    <div>
                        <Col className={AboutUsCss.label} xs={12} md={12} lg={12}>
                            <h1>里程碑</h1>
                        </Col>
                    </div>
                </Row>
            </Container>
            <VerticalTimeline>
                {/* 1 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{
                        background: 'url("./images/year/space-left.png")',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        color: '#1A517A',

                        backgroundRepeat: 'no-repeat',
                    }}
                    contentArrowStyle={{
                        borderRight: '7px dotted  #211E55',
                    }}
                    iconStyle={{
                        background: 'url("./images/year/2022-icon.png")',
                        backgroundSize: 'cover',
                        color: '#fff',
                    }}
                >
                    <h3 className="vertical-timeline-element-title">持續成長</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <FaBeer />
                    </h4>
                    <p>旺捷與您同在</p>
                </VerticalTimelineElement>
                {/* 2 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{
                        background: 'url("./images/year/space-left.png")',
                        backgroundSize: 'cover',
                        color: '#1A517A',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                    contentArrowStyle={{
                        borderRight: '7px dotted  #211E55',
                    }}
                    iconStyle={{
                        background: 'url("./images/year/2020-icon.png")',
                        backgroundSize: 'cover',
                        color: '#fff',
                    }}
                >
                    <h3 className="vertical-timeline-element-title">工業局</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <FaBeer />
                    </h4>
                    <p>獲得工業局標竿案例的榮譽</p>
                </VerticalTimelineElement>
                {/* 3 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{
                        background: 'url("./images/year/space-left.png")',
                        backgroundSize: 'cover',
                        color: '#1A517A',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                    contentArrowStyle={{
                        borderRight: '7px dotted  #211E55',
                    }}
                    iconStyle={{
                        background: 'url("./images/year/2020-icon.png")',
                        backgroundSize: 'cover',
                        color: '#fff',
                    }}
                >
                    <h3 className="vertical-timeline-element-title">國發基金</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <FaBeer />
                    </h4>
                    <p>持續獲得國發基金投資肯定，對公司長遠發長有極大的幫助</p>
                </VerticalTimelineElement>
                {/* 4 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{
                        background: 'url("./images/year/space-left.png")',
                        backgroundSize: 'cover',
                        color: '#1A517A',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                    contentArrowStyle={{
                        borderRight: '7px dotted  #211E55',
                    }}
                    iconStyle={{
                        background: 'url("./images/year/2019-icon.png")',
                        backgroundSize: 'cover',
                        color: '#fff',
                    }}
                >
                    <h3 className="vertical-timeline-element-title">工研院</h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        <FaBeer />
                    </h4>
                    <p>榮獲工研院電光所投資肯定</p>
                </VerticalTimelineElement>
                {/* 5 */}
            </VerticalTimeline>
        </div>
    )
}

export default Timeline
