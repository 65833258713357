import React, { useEffect, useState } from 'react'
import { ImCrying } from 'react-icons/im'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import ProjectCss from '../../Style/Project.module.scss'
import { AiOutlineClose } from 'react-icons/ai'
function ProjectVideo() {
    const defaultVideo = [
        {
            key: 1,
            title: '國立國父紀念館',
            text: '國立國父紀念館',
            technology: 'AR',
            category: '現地導覽',

            url: './images/project/webp/國父紀念館宣傳影片.webp',
            release_year: 2020,
            src: 'https://www.youtube.com/embed/OEhSwwWKlhU',
        },
        {
            key: 2,
            title: '科技部南部科學園區管理局',
            text: 'XR防災模擬訓練場域資訊系統開發案',
            technology: 'MR',
            category: '教育訓練',

            url: './images/project/webp/南科影片有聲版1.webp',
            release_year: 2020,
            src: 'https://www.youtube.com/embed/6UznGyryQi4',
        },
        {
            key: 3,
            title: '內政部營建署下水道建設VR導覽',
            text: '內政部營建署下水道建設VR導覽',
            technology: 'VR',
            category: '教育訓練',

            url: './images/project/webp/虛擬實境應用於下水道建設導覽開發工作.webp',
            release_year: 2020,
            src: 'https://www.youtube.com/embed/0QC3-kueQa8',
        },
        {
            key: 4,
            title: '天和鮮物AR互動APP',
            text: '天和鮮物AR互動APP',
            technology: 'AR',
            category: '現地導覽',

            url: './images/project/webp/天和鮮物AR互動APP開發.webp',
            release_year: 2022,
            src: 'https://www.youtube.com/embed/6efpeyfduuM',
        },
        {
            key: 5,
            title: '毛寶洗衣槽專用去污劑AR互動APP',
            text: '毛寶洗衣槽專用去污劑AR互動APP',
            technology: 'AR',
            category: '互動娛樂',

            url: './images/project/webp/毛寶洗衣槽專用去污劑AR互動APP開發.webp',
            release_year: 2021,
            src: 'https://www.youtube.com/embed/tYeihvQsrgI',
        },
        {
            key: 6,
            title: '毛寶電鍋清潔劑AR互動APP',
            text: '毛寶電鍋清潔劑AR互動APP',
            technology: 'AR',
            category: '互動娛樂',

            url: './images/project/webp/毛寶電鍋清潔劑AR互動APP開發.webp',
            release_year: 2021,
            src: 'https://www.youtube.com/embed/_FMil1giJPc',
        },
        {
            key: 7,
            title: '可口可樂',
            text: '可口可樂飲料機AR體感互動',
            technology: 'AR',

            url: './images/project/webp/可口可樂飲料機AR體感互動開發.webp',
            category: '互動娛樂',
            release_year: 2020,
            src: 'https://www.youtube.com/embed/XfKnQnEdxs8',
        },
        {
            key: 8,
            title: '亞東石化',
            text: '亞東石化擴增實境訓練',
            technology: 'MR',

            url: './images/project/webp/亞東石化AR.webp',
            category: '教育訓練',
            release_year: 2021,
            src: 'https://www.youtube.com/embed/ZSL9xXrEtU4',
        },
        {
            key: 9,
            title: '混合實境智能機台維修服務平台開發計畫',
            text: '混合實境智能機台維修服務平台開發計畫',
            technology: 'MR',

            url: './images/project/webp/混合實境智能機台維修服務平台開發計畫.webp',
            category: '教育訓練',
            release_year: 2020,
            src: 'https://www.youtube.com/embed/t8ABnb-wSco',
        },
        {
            key: 10,
            title: '亞東石化',
            text: '亞東石化火災爆炸VR訓練',
            technology: 'VR',

            url: './images/project/webp/虛擬實境技術於石化業火災爆炸之工具開發.webp',
            category: '教育訓練',
            release_year: 2020,
            src: 'https://www.youtube.com/embed/kpKVb8cQayo',
        },

        {
            key: 11,
            title: '遠傳',
            text: '遠傳智慧水利防災AR系統開發',
            technology: 'AR',

            url: './images/project/webp/遠傳智慧水利防災AR系統開發.webp',
            category: '現地導覽',
            release_year: 2020,
            src: 'https://www.youtube.com/embed/9C1h75xMqD8',
        },

        {
            key: 12,
            title: '戀家小舖AR互動APP',
            text: '戀家小舖AR互動APP',
            technology: 'AR',

            url: './images/project/webp/戀家小舖3D空間規劃與AR互動APP開發.webp',
            category: '互動娛樂',
            release_year: 2020,
            src: 'https://www.youtube.com/embed/kyYBem60idc',
        },
    ]
    //
    const [filteredList, setFilteredList] = useState(defaultVideo)
    // Selected Technology name filter
    const [selectedTechnology, setSelectedTechnology] = useState('')
    // Selected Category filter
    const [selectedCategory, setSelectedCategory] = useState()
    const filterByTechnology = (filteredData) => {
        // Avoid filter for empty string
        if (!selectedTechnology) {
            return filteredData
        }

        const filteredVideos = filteredData.filter(
            (video) => video.technology.split(' ').indexOf(selectedTechnology) !== -1
        )
        return filteredVideos
    }
    const filterByCategory = (filteredData) => {
        // Avoid filter for null value
        if (!selectedCategory) {
            return filteredData
        }

        const filteredVideos = filteredData.filter((video) => video.category === selectedCategory)
        return filteredVideos
    }
    // Update seletedTechnology state
    const handleTechnologyChange = (event) => {
        setSelectedTechnology(event.target.value)
    }

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value)
    }

    useEffect(() => {
        var filteredData = filterByTechnology(defaultVideo)
        filteredData = filterByCategory(filteredData)
        setFilteredList(filteredData)
    }, [selectedTechnology, selectedCategory])
    //
    function Modale({ isOpen, onClose, item }) {
        return (
            isOpen && (
                <>
                    <div className={ProjectCss.PopUp}>
                        <div className={ProjectCss.PopBox}>
                            <iframe
                                className={ProjectCss.PopIframe}
                                id="myVideo"
                                src={item.src}
                                allowFullScreen={true}
                            />
                            <h4>{item.title}</h4>
                        </div>

                        <div className={ProjectCss.popupX}>
                            <Button
                                style={{ width: '47px', height: '47px' }}
                                className="rounded-circle"
                                variant="danger"
                                size="lg"
                                type="button"
                                aria-label="Close"
                                onClick={onClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        </div>
                    </div>
                </>
            )
        )
    }
    //
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const [focusProject, setFocusProject] = useState(null)

    const onOpenModale = (item) => {
        setModalIsOpen(true)
        setFocusProject(item)
        document.body.style.backgroundColor = ' rgb(111, 111, 111, 1)'
    }

    const onCloseModale = () => {
        setModalIsOpen(false)
        setFocusProject(null)
        document.body.style.backgroundColor = ''
    }
    //
    return (
        <div>
            <Container>
                <Row className="d-flex  justify-content-center">
                    <Col className={ProjectCss.label} xs={12} md={12} lg={12}>
                        <h1>作品案例</h1>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h5 style={{ textAlign: 'left' }}>技術</h5>

                        <Form.Select
                            className={ProjectCss.formSelect}
                            id="Category-input"
                            value={selectedTechnology}
                            onChange={handleTechnologyChange}
                        >
                            <option value="">全部</option>
                            <option value="VR">VR</option>
                            <option value="MR">MR</option>
                            <option value="AI">AI</option>
                            <option value="AR">AR</option>
                        </Form.Select>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h5 style={{ textAlign: 'left' }}>功能</h5>
                        <Form.Select
                            className={ProjectCss.formSelect}
                            id="Category-input"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            <option value="">全部</option>
                            <option value="互動娛樂">互動娛樂</option>
                            <option value="教育訓練">教育訓練</option>
                            <option value="現地導覽">現地導覽</option>
                            <option value="虛擬展間">虛擬展間</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Container>

            <div>
                <Modale isOpen={modalIsOpen} onClose={onCloseModale} item={focusProject} />
            </div>
            <Container fluid>
                <Row>
                    {filteredList.length ? (
                        filteredList.map((item) => (
                            <>
                                <Col className="d-flex  justify-content-center  mt-5 " xs={12} md={4} lg={4}>
                                    <Card className={ProjectCss.cardSize}>
                                        <div className={ProjectCss.productBox}>
                                            <Card.Img className={ProjectCss.cardImgSize} variant="top" src={item.url} />
                                            <a>
                                                <div className={ProjectCss.cardMask}>
                                                    <div>
                                                        <h5>
                                                            {item.technology}
                                                            <br></br>
                                                            {item.title}
                                                        </h5>
                                                        <Button
                                                            variant="outline-light"
                                                            onClick={() => onOpenModale(item)}
                                                        >
                                                            View
                                                        </Button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <Card.Title className={ProjectCss.cardTag}>
                                            <span>{item.technology}</span>&nbsp;&nbsp;
                                            <span>{item.category}</span>
                                        </Card.Title>
                                        <Card.Body className={ProjectCss.cardBody}>
                                            <div className={ProjectCss.cardTitle}>
                                                <Card.Title>
                                                    <h5>{item.text}</h5>
                                                </Card.Title>
                                                <Card.Text>
                                                    <h6> 專案執行時間：{item.release_year}</h6>
                                                </Card.Text>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>{' '}
                            </>
                        ))
                    ) : (
                        <div>
                            <Container
                                fluid
                                style={{ height: '500px' }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Row className="my-5">
                                    <Col lg={12} className="mb-3">
                                        <ImCrying size={70} />
                                    </Col>

                                    <Col lg={12}>
                                        <Col>
                                            <h1>無搜尋結果</h1>
                                            <p>抱歉，你搜尋是空的唷!</p>
                                        </Col>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )}
                </Row>
            </Container>
        </div>
    )
}

export default ProjectVideo
