import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HomeCss from '../../Style/Home.module.scss'
function Company() {
    return (

        <>
            <Container fluid className={HomeCss.Company}>
                <Row className="d-flex align-items-center justify-content-center mb-5">
                    {' '}
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/易利修.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/中衛.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/數位時代.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/聯合技師公會.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/巨匠.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img
                            className={HomeCss.Icon}
                            src={'./icon/company/蒲公英希望會基金.png'}
                            alt=""
                            loading="lazy"
                        />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/中華電信.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/遠傳.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/友嘉.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/仁寶.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/realwear.png'} alt="" loading="lazy" />
                    </Col>
                    {/* 1 */}
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/jorjin.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.IconSmall} src={'./icon/company/台電.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.IconSmall} src={'./icon/company/勞動.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.IconSmall} src={'./icon/company/水利署.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.IconSmall} src={'./icon/company/日月光.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.IconSmall} src={'./icon/company/亞東.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.IconSmall} src={'./icon/company/文欣.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/工研院.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/台灣世曦.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/國際互動.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/steamship.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/遠東.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/microsoft.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/台塑.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/vuforia.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/PTC.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/CTCI.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/epson.png'} alt="" loading="lazy" />
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4}>
                        <img className={HomeCss.Icon} src={'./icon/company/everest.png'} alt="" loading="lazy" />
                    </Col>
                </Row>
            </Container>
            <Container fluid className={HomeCss.CompanyRWD}>
                <Row className="d-flex align-items-center justify-content-center mb-5">
                    <img src={'./images/company-webp.webp'} alt="" loading="lazy" />{' '}
                </Row>
            </Container>
        </>
    )
}

export default Company
