import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AboutUsCss from '../../Style/AboutUs.module.scss'
function About() {
    return (
        <div>
            {' '}
            <Container className="d-flex  justify-content-center align-items-center">
                <Row>
                    <div>
                        <Col className={AboutUsCss.label} xs={12} md={12}>
                            <h1>關於我們</h1>
                        </Col>
                        <Col xs={12} md={12}>
                            <h4>
                                We JUMP成立於2014年5月，是致力於發展 XR(AR/VR/MR)+AI
                                的混合應用開發團隊，活躍於智慧人機互動平台的APP應用建置。運用空間辨識技術(空間定位)搭配圖像辨識技術，讓AR數位科技展示能更具直覺性與順暢的導覽。開發多人協作VR系統，達成遠端多人參與，及虛擬空間中的合作型任務需求，並持續拓展虛擬的可能性，開發元宇宙虛擬展間業務。
                            </h4>
                        </Col>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default About
