import React from 'react'
import AIBanner from '../Components/TechAI/AIBanner'
import AIProject from '../Components/TechAI/AIProject'

function TechAI() {
    return (
        <div>
            <AIBanner />
            <AIProject />
        </div>
    )
}

export default TechAI
