import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FaFacebookSquare } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaTwitterSquare } from 'react-icons/fa'

function Footer() {
    return (
        <div>
            {' '}
            <Container fluid className="mt-3">
                <Row>
                    <Col className="d-flex align-items-center justify-content-start" lg={4} md={4} sm={12} xs={12}>
                        <h6 style={{ textAlign: 'left', marginLeft: '5%' }}>
                            <p>旺捷智能感知股份有限公司</p>
                            <p> 100 台北市中正區忠孝東路一段9號6樓 </p>
                            <p>Phone: (02)2322-3938&nbsp;&nbsp; Email:service@wj-ar.com</p>
                        </h6>
                    </Col>
                    <Col className="d-flex  align-items-end justify-content-center" lg={4} md={4} sm={12} xs={12}>
                        <p>© 2022 We JUMP. All rights reserved</p>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center" lg={4} md={4} sm={12} xs={12}>
                        {' '}
                        <h1 style={{ fontSize: '40px' }}>
                            <FaFacebookSquare className="m-1" />
                            <FaYoutube className="m-1" />
                            <FaInstagram className="m-1" />
                            <FaTwitterSquare className="m-1" />
                        </h1>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer
