import React from 'react'
import MRBannerVideo from '../Components/TechMR/MRBannerVideo'
import MRPic from '../Components/TechMR/MRPic'
import MRSwipe from '../Components/TechMR/MRSwipe'
import Unique from '../Components/Unique'

function TechMR() {
    return (
        <div>
            <MRBannerVideo />
            <Unique />
            <MRSwipe />
            <MRPic />
        </div>
    )
}

export default TechMR
